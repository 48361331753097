import {type Editor} from '@englex/slate';

import ToolbarButton, {type ToolbarButtonOptions} from '../../ToolbarButton';
import {isImageBlock} from '../../../../utils';
import toggleImageFloat from './utils';
import {BlockFloat} from '../../../../interface';

abstract class ImageTextWrap extends ToolbarButton {
  public abstract float: BlockFloat;

  public constructor({toolbar}: ToolbarButtonOptions) {
    super({toolbar});
  }

  public isDisabled({value}: Editor): boolean {
    return !value.selection?.isFocused || !value.selection.isCollapsed;
  }

  public isActive = (editor: Editor) => {
    if (!editor.value.selection?.isCollapsed) {
      return false;
    }
    const block = editor.value.startBlock;
    if (block && isImageBlock(block)) {
      const float = block.data.get('float');
      if (float) {
        return float === this.float;
      }
      return this.float === BlockFloat.CENTER;
    }

    return false;
  };

  protected toggleChange = (change: Editor) => toggleImageFloat(change, this.float);
}

export default ImageTextWrap;

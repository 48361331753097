import {type Editor} from '@englex/slate';

import createDialogTable from './createDialogTable';

export function insertDialogTable(change: Editor, defaultRowsNumber: number): Editor {
  const {value} = change;

  if (!value.selection?.start) {
    return change;
  }

  const columnsInDialog = 2;

  // Create the table node
  const table = createDialogTable(true, columnsInDialog, defaultRowsNumber);

  return change.insertBlock(table);
}

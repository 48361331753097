import {type Plugin} from '@englex/slate-react';
import {type Editor} from '@englex/slate';

import ToolbarButton from '../../../ToolbarButton';
import {ButtonType} from '../../../interface';
import {buttonTitle} from '../../i18n';
import DialogTable from './DialogTable';
import {insertDialogTable} from './changes';
import startOrEndBlockInTable from '../utils/startOrEndBlockInTable';
import AddRowAfter from '../AddRowAfter';
import AddRowBefore from '../AddRowBefore';
import RemoveRow from '../RemoveRow';
import DialogRow from './DialogRow';
import DialogCell from './DialogCell';
import {DialogPaste} from './DialogPaste';

export default class AddDialogBtn extends ToolbarButton {
  public icon = 'comments';
  public type = ButtonType.DIALOG;
  public title = buttonTitle[ButtonType.DIALOG];
  public plugins: Plugin[] = [
    new DialogTable(),
    new DialogRow(),
    new DialogCell([new AddRowAfter(), new AddRowBefore(), new RemoveRow()]),
    DialogPaste()
  ];
  public shortcut = 'mod+alt+d';
  public defaultRowsNumber = 3;

  public toggleChange = (ch: Editor) => ch.command(insertDialogTable, this.defaultRowsNumber);

  public isDisabled(editor: Editor): boolean {
    if (super.isDisabled(editor)) {
      return true;
    }

    return startOrEndBlockInTable(editor.value);
  }
}

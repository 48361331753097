import {type Editor} from '@englex/slate';

import ToolbarButton from '../../ToolbarButton';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {insertRow} from './Changes/insertRow';

export default class AddRowBefore extends ToolbarButton {
  public icon = 'add-row-before';
  public type = ButtonType.ADD_ROW_BEFORE;
  public title = buttonTitle[ButtonType.ADD_ROW_BEFORE];
  protected neverDisable = true;

  public toggleChange = (ch: Editor) => ch.command(insertRow, true);
}

import React from 'react';
import {type Data, type Editor, type Next} from '@englex/slate';
import {type Editor as ReactEditor} from '@englex/slate-react';

import {clamp} from 'helpers/clamp';
import isShortcut from 'helpers/shortcut';

import ToolbarButton, {type ToolbarButtonOptions} from '../../../ToolbarButton';
import {ButtonType, type RenderToolbarButtonProps} from '../../../interface';
import {type DataMap, type ImageBlock, type ImageData} from '../../../../../interface';
import {buttonTitle} from '../../i18n';
import {isImageBlock} from '../../../../../utils';
import {ImageResizeTooltip} from './ImageResizeTooltip';
import {imageReduceSizeFactor} from './constants';

interface Size {
  originalWidth: number;
  originalHeight: number;
}

interface Options extends ToolbarButtonOptions {
  maxWidth: number;
  minWidth: number;
  minHeight: number;
  getOriginalSize: (imageId: number) => Size;
}

export class ImageResizeButton extends ToolbarButton {
  public type = ButtonType.IMAGE_RESIZE;
  public title = buttonTitle.ImageResize;
  public icon = 'virc-resize';

  private readonly maxWidth: number;
  private readonly minWidth: number;
  private readonly minHeight: number;
  private readonly getOriginalSize: (imageId: number) => Size;

  public constructor({maxWidth, minWidth, minHeight, getOriginalSize, ...rest}: Options) {
    super(rest);

    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.minHeight = minHeight;
    this.getOriginalSize = getOriginalSize;
  }

  protected toggleChange: undefined;

  public handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  public renderToolbarButton(props: RenderToolbarButtonProps) {
    const {editor} = props;

    const toolbarButton = super.renderToolbarButton(props) as React.ReactElement;

    return (
      <ImageResizeTooltip
        key={ButtonType.IMAGE_RESIZE}
        getTooltipContainer={this.getOwnTooltipContainer}
        onResize={sizeFactor => editor.command(this.resizeImage, sizeFactor)}
      >
        {toolbarButton}
      </ImageResizeTooltip>
    );
  }

  private resize = (imageWidth: number, imageHeight: number, sizeFactor: number) => {
    const width = clamp(Math.ceil(imageWidth * (1 / sizeFactor)), this.minWidth, this.maxWidth);

    const ratio = imageWidth / imageHeight;
    const height = width / ratio;

    if (height < this.minHeight) return {width: this.minHeight * ratio, height: this.minHeight};

    return {width, height};
  };

  private getResizeSize = (data: Data, sizeFactor: number) => {
    const imageId = data.get('id');

    const {originalWidth, originalHeight} = this.getOriginalSize(imageId);

    return this.resize(originalWidth, originalHeight, sizeFactor);
  };

  private resizeImage = (change: Editor, sizeFactor: number) => {
    const {value} = change;

    if (value.selection?.isCollapsed && value.startBlock && isImageBlock(value.startBlock)) {
      const {key, data}: ImageBlock = value.startBlock;

      const {width, height} = this.getResizeSize(data, sizeFactor);

      change.setNodeByKey(key, {
        data: data.withMutations((d: DataMap<ImageData>) =>
          d.set('width', width).set('height', height)
        )
      });
    }
  };

  private getOwnTooltipContainer = (): HTMLElement => {
    return document.querySelector('.' + ButtonType.IMAGE_RESIZE) || document.documentElement;
  };

  public onKeyDown = (
    event: React.KeyboardEvent,
    editor: ReactEditor & Editor,
    next: Next
  ): boolean | void => {
    for (const sizeFactor of Object.values(imageReduceSizeFactor)) {
      if (isShortcut(event, 'ctrl+shift+' + sizeFactor)) {
        return this.resizeImage(editor, sizeFactor);
      }
    }

    return next();
  };
}

import {type Editor} from '@englex/slate';

import TablePosition from '../utils/TablePosition';

export function removeRow(change: Editor, at?: number): Editor {
  const {value} = change;

  if (!value.selection) return change;

  const {start} = value.selection;

  if (!start.key) {
    return change;
  }
  const pos = TablePosition.create(value.document, start.key);

  let rowKey;
  if (typeof at === 'undefined') {
    rowKey = pos.row.key;
  } else {
    rowKey = pos.table.nodes.get(at).key;
  }

  return change.removeNodeByKey(rowKey);
}

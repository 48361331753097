import {type Editor} from '@englex/slate';

import ToolbarButton from '../../ToolbarButton';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {insertRow} from './Changes/insertRow';

export default class AddRowAfter extends ToolbarButton {
  public icon = 'add-row-after';
  public type = ButtonType.ADD_ROW_AFTER;
  public title = buttonTitle[ButtonType.ADD_ROW_AFTER];
  protected neverDisable = true;

  public toggleChange = (ch: Editor) => ch.command(insertRow, false);
}

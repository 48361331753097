import {type Editor, type SlateError} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';

import DialogRowRenderer from '../../../../../plugins/renderers/Table/Dialog/DialogRow';
import {type SchemaBlockPlugin, type SchemaBlockRules} from '../../../interface';
import {SlateBlock} from '../../../../../interface';
import {isBlockOfType} from '../../../../../utils';
import createCell from '../utils/createCell';

export default class DialogRow extends DialogRowRenderer implements SchemaBlockPlugin, Plugin {
  public blockRules = (): SchemaBlockRules => ({
    type: SlateBlock.DIALOG_ROW,
    rules: {
      nodes: [
        {
          match: [{type: SlateBlock.DIALOG_CELL}],
          min: 2,
          max: 2
        }
      ]
    },
    normalizer: {
      predicate: ({node}: SlateError) => isBlockOfType(node, SlateBlock.DIALOG_ROW),
      reasons: {
        child_min_invalid: (change: Editor, {node}: SlateError) => {
          const {document} = change.value;
          const cell = createCell();
          if (!document.getPreviousSibling(node.key)) {
            change.insertNodeByKey(node.key, 0, cell);
            return true;
          }
          if (!document.getNextSibling(node.key)) {
            change.insertNodeByKey(node.key, 1, cell);
            return true;
          }
          return;
        }
      }
    }
  });
}

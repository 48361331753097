import {
  Block,
  type Document,
  type Editor,
  type Next,
  type Query,
  type SchemaProperties,
  type SlateError
} from '@englex/slate';
import {type Editor as ReactEditor} from '@englex/slate-react';
import type React from 'react';

import {isBlockOfType} from '../../../../../utils';
import {
  getBeforeInsertFragmentPlugins,
  getToolbarButtonDisablers,
  SlateBlock,
  SlateObject
} from '../../../../../interface';
import {
  type ButtonDisablerPlugin,
  ButtonType,
  type InsertFragmentPlugin,
  type PluginDisablerPredicate,
  type SchemaBlockPlugin,
  type SchemaBlockRules
} from '../../../interface';
import onKeyDown from '../Handlers/onKeyDown';
import {copyFragment, getDefaultLeafBlocksFragment, logNormalizeError} from '../../../utils';
import startOrEndBlockInTable from '../utils/startOrEndBlockInTable';
import {startAndEndBlockInOneCell} from '../utils/startAndEndBlockInOneCell';
import {selectionHasDialog} from '../utils/selectionHasDialog';
import DialogTableRenderer from '../../../../../plugins/renderers/Table/Dialog/DialogTable';
import isFirstCell from '../utils/isFirstCell';
import './DialogTable.scss';

export default class DialogTable
  extends DialogTableRenderer
  implements SchemaBlockPlugin, ButtonDisablerPlugin, InsertFragmentPlugin
{
  public block = SlateBlock.DIALOG_TABLE;
  public onKeyDown = onKeyDown;

  public disableButtons = {
    [ButtonType.ORDERED_LIST]: selectionHasDialog,
    [ButtonType.UNORDERED_LIST]: selectionHasDialog,
    [ButtonType.IMAGE]: selectionHasDialog,
    [ButtonType.LINK]: selectionHasDialog,
    [ButtonType.BOLD]: isFirstCell,
    [ButtonType.TEXT_ALIGNMENT]: selectionHasDialog,
    [ButtonType.CHAR_SELECTOR]: (editor: Editor) =>
      startOrEndBlockInTable(editor.value) && !startAndEndBlockInOneCell(editor.value)
  };

  public schema: SchemaProperties = {
    rules: [
      {
        match: {object: SlateObject.DOCUMENT},
        first: n => !isBlockOfType(n, this.block),
        last: n => !isBlockOfType(n, this.block),
        normalize: (change: Editor, error: SlateError) => {
          if (error.code === 'first_child_invalid') {
            change.insertNodeByKey(error.node.key, 0, Block.create(SlateBlock.DEFAULT));
            logNormalizeError(error, 'DialogTable.schema.rules', true, '0');
            return;
          }
          if (error.code === 'last_child_invalid') {
            change.insertNodeByKey(
              error.node.key,
              (error.node as Document).nodes.size,
              Block.create(SlateBlock.DEFAULT)
            );
            logNormalizeError(error, 'DialogTable.schema.rules', true, '0');
            return;
          }
          logNormalizeError(error, 'DialogTable.schema.rules', false, '0');
        }
      }
    ]
  };

  public onQuery = (query: Query, editor: ReactEditor & Editor, next: Next) => {
    if (query.type === getToolbarButtonDisablers) {
      const predicates: PluginDisablerPredicate[] = next() || [];
      const buttonType: ButtonType = query.args[0];
      if (buttonType && Object.keys(this.disableButtons).includes(buttonType)) {
        predicates.unshift(this.disableButtons[buttonType]);
      }
      return predicates;
    }
    if (query.type === getBeforeInsertFragmentPlugins) {
      const plugins = next() || [];
      plugins.unshift(this);
      return plugins;
    }
    return next();
  };

  public onBeforeInsertFragment = (
    event: React.ClipboardEvent,
    tmpChange: Editor,
    change: Editor
  ) => {
    if (!startOrEndBlockInTable(change.value)) {
      return;
    }

    if (!startAndEndBlockInOneCell(change.value)) {
      event.preventDefault();
      // prevent insert fragment
      return true;
    }

    return getDefaultLeafBlocksFragment(tmpChange, false);
  };

  public blockRules = (): SchemaBlockRules => ({
    type: SlateBlock.DIALOG_TABLE,
    rules: {
      nodes: [
        {
          match: [{type: SlateBlock.DIALOG_ROW}]
        }
      ],
      next: n => !isBlockOfType(n, SlateBlock.DIALOG_TABLE)
    },
    normalizer: {
      predicate: this.normalizerPredicate,
      reasons: {
        next_sibling_invalid: (change: Editor, {node, child}: SlateError) => {
          const newParagraph = Block.create({type: SlateBlock.DEFAULT});
          const index = (node as Document).nodes.findIndex(
            targetNodeSibling => targetNodeSibling!.key === (child as Block).key
          );
          change.insertNodeByKey((node as Document).key, index + 1, newParagraph);
          return true;
        },
        child_type_invalid: (change: Editor, {node}: SlateError) => {
          change.removeNodeByKey(node.key);
          return true;
        }
      }
    }
  });

  public onCopy = (event: React.ClipboardEvent, change: ReactEditor & Editor, next: Next) => {
    const handled = this.handleCopy(event, change);
    return !handled ? next() : undefined;
  };

  public onCut = (event: React.ClipboardEvent, change: ReactEditor & Editor, next: Next) => {
    if (this.handleCopy(event, change)) {
      window.requestAnimationFrame(() => {
        change.command((newChange: Editor) => newChange.delete());
      });
      return;
    }
    return next();
  };

  private handleCopy = (event: React.ClipboardEvent, change: Editor) => {
    const {value} = change;
    const {startBlock, endBlock} = value;

    if (!startBlock || !endBlock || !startOrEndBlockInTable(value)) {
      return;
    }

    if (startAndEndBlockInOneCell(value)) {
      // clone leaf blocks only, without parent wrappers
      copyFragment(event, getDefaultLeafBlocksFragment(change));
      return true;
    }

    return;
  };

  private normalizerPredicate = ({node, code, child}: SlateError) => {
    if (isBlockOfType(node, SlateBlock.DIALOG_TABLE)) {
      return true;
    }
    return (
      isBlockOfType(child as Block, SlateBlock.DIALOG_TABLE) && code === 'next_sibling_invalid'
    );
  };
}

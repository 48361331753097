import {type Block, type Editor} from '@englex/slate';

import {isBlockOfType} from '../../../../../utils';
import {SlateBlock} from '../../../../../interface';

const isFirstCell = (editor: Editor) => {
  const {blocks, document} = editor.value;
  const block = blocks.find(b =>
    isBlockOfType(document.getParent(b!.key) as Block, SlateBlock.DIALOG_CELL)
  );
  if (block) {
    const parent = document.getParent(block.key);
    if (parent) {
      return !document.getPreviousSibling(parent.key);
    }
  }
  return false;
};

export default isFirstCell;

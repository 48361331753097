import {Block, Text} from '@englex/slate';
import {List} from 'immutable';

import {SlateBlock} from '../../../../../interface';

function createCell(): Block {
  return Block.create({
    type: SlateBlock.DIALOG_CELL,
    nodes: List([createEmptyContent()])
  });
}

/**
 * Create a new default content block
 */
function createEmptyContent(): Block {
  return Block.create({
    type: SlateBlock.DEFAULT,
    nodes: List([Text.create('')])
  });
}

export default createCell;

import ImageTextWrap from './ImageTextWrap';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {BlockFloat} from '../../../../interface';

class ImageTextWrapRight extends ImageTextWrap {
  public float = BlockFloat.RIGHT;
  public type = ButtonType.IMAGE_TEXT_WRAP_RIGHT;
  public title = buttonTitle.ImageTextWrapRight;
  public icon = 'virc-text-wrapping-left';
  public shortcut = 'mod+shift+alt+r';
}

export default ImageTextWrapRight;

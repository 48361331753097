import ImageTextWrap from './ImageTextWrap';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {BlockFloat} from '../../../../interface';

class ImageTextWrapLeft extends ImageTextWrap {
  public float = BlockFloat.LEFT;
  public type = ButtonType.IMAGE_TEXT_WRAP_LEFT;
  public title = buttonTitle.ImageTextWrapLeft;
  public icon = 'virc-text-wrapping-right';
  public shortcut = 'mod+shift+alt+l';
}

export default ImageTextWrapLeft;

import {type Editor} from '@englex/slate';
import type React from 'react';

import TablePosition from '../utils/TablePosition';
import moveSelectionBy from '../Changes/moveSelectionBy';
import {insertRow} from '../Changes/insertRow';

/**
 * Select all text of current block.
 */
function selectAllText(change: Editor): Editor {
  const {value} = change;
  const {startBlock} = value;

  return change.moveToRangeOfNode(startBlock!);
}

/**
 * Pressing "Tab" moves the cursor to the next cell
 * and select the whole text
 */
function onTab(event: React.KeyboardEvent, change: Editor) {
  event.preventDefault();
  const {value} = change;
  const direction = event.shiftKey ? -1 : +1;

  // Create new row if needed
  const {selection} = value;
  if (!selection?.start.key) {
    return change;
  }
  const pos = TablePosition.create(value.document, selection.start.key);
  if (pos.isFirstCell() && direction === -1) {
    insertRow(change, true);
  } else if (pos.isLastCell() && direction === 1) {
    insertRow(change, false);
  }

  // Move back to initial cell (insertRow moves selection automatically).
  if (!pos.isFirstCell()) {
    change.select(selection);
  }

  // Move
  moveSelectionBy(change, direction, 0);

  // Select all cell.
  return selectAllText(change);
}

export default onTab;

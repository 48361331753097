import {Block} from '@englex/slate';
import {Range} from 'immutable';

import {SlateBlock} from '../../../../../interface';
import createCell from './createCell';

function createRow(columns: number): Block {
  const cellNodes = Range(0, columns)
    .map(() => createCell())
    .toList();

  return Block.create({
    type: SlateBlock.DIALOG_ROW,
    nodes: cellNodes
  });
}

export default createRow;

import {type Editor} from '@englex/slate';
import type React from 'react';

import isShortcut from 'helpers/shortcut';

import TablePosition from '../utils/TablePosition';
import moveSelectionBy from '../Changes/moveSelectionBy';

function onUpDown(event: React.KeyboardEvent, change: Editor): void | Editor {
  const {value} = change;
  if (!value.selection?.start.key) {
    return change;
  }
  const direction = isShortcut(event, 'up') ? -1 : +1;
  const pos = TablePosition.create(value.document, value.selection.start.key);

  if ((pos.isFirstRow() && direction === -1) || (pos.isLastRow() && direction === +1)) {
    // Let the default behavior move out of the table
    return undefined;
  }

  if (direction === -1 && !pos.isTopOfCell()) {
    return undefined;
  }

  if (direction === +1 && !pos.isBottomOfCell()) {
    return undefined;
  }

  event.preventDefault();

  moveSelectionBy(change, 0, direction);

  return change;
}

export default onUpDown;

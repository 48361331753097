import {type Block, type Value} from '@englex/slate';

export function startAndEndBlockInOneCell({startBlock, endBlock, document}: Value) {
  if (!startBlock || !endBlock) {
    return false;
  }
  const startBlockParent = document.getParent(startBlock.key) as Block;
  const endBlockParent = document.getParent(endBlock.key) as Block;

  return startBlockParent.key === endBlockParent.key;
}

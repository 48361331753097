import {type Plugin} from '@englex/slate-react';
import {Data, type SlateError} from '@englex/slate';

import {Example as ExampleRenderer} from '../../../../plugins/renderers/Example';
import {ButtonType, type SchemaBlockPlugin, type SchemaBlockRules} from '../../interface';
import {SlateBlock, SlateObject} from '../../../../interface';
import {isBlockOfType} from '../../../../utils';
import ToolbarButton from '../../ToolbarButton';
import {toggleExample} from './commands';
import {isExampleActive} from './queries';
import {buttonLabel, buttonTitle} from '../i18n';

import './ToggleExample.scss';

export class ToggleExample extends ToolbarButton implements Plugin, SchemaBlockPlugin {
  public readonly plugins = [ExampleRenderer()];
  public type = ButtonType.TOGGLE_EXAMPLE;
  public title? = buttonTitle.ToggleExample;
  public icon = undefined;
  public label = buttonLabel.ToggleExample;
  public shortcut = 'mod+opt+e';

  protected toggleChange = toggleExample;

  public isActive = isExampleActive;

  public blockRules = (): SchemaBlockRules => {
    return {
      type: SlateBlock.EXAMPLE,
      rules: {
        parent: {object: SlateObject.DOCUMENT},
        data: (d: Data) => d?.size === 0,
        nodes: [
          {
            match: {object: 'block'},
            min: 1
          }
        ]
      },
      normalizer: {
        predicate: ({node}: SlateError) => isBlockOfType(node, SlateBlock.EXAMPLE),
        reasons: {
          node_data_invalid: (editor, error) => {
            editor.setNodeByKey(error.node.key, {data: Data.create({})});
            return true;
          },
          parent_object_invalid: (editor, error) => {
            editor.unwrapNodeByKey(error.node.key);
            return true;
          },
          child_object_invalid: (editor, error) => {
            editor.setNodeByKey(error.node.key, {type: SlateBlock.DEFAULT});
            return true;
          }
        }
      }
    };
  };
}

import {type Block, type Editor} from '@englex/slate';

import TablePosition from '../utils/TablePosition';
import createRow from '../utils/createRow';

export function insertRow(change: Editor, before: boolean) {
  const {value} = change;

  if (!value.selection) return change;

  const {start} = value.selection;

  if (!start.key) {
    return change;
  }
  const pos = TablePosition.create(value.document, start.key);
  const {table} = pos;

  // Create a new row with the right count of cells
  const columns = (table.nodes.get(0) as Block).nodes.size;
  const newRow = createRow(columns);

  const index = before ? pos.getRowIndex() : pos.getRowIndex() + 1;

  return change
    .insertNodeByKey(table.key, index, newRow)
    .moveToEndOfNode(newRow.nodes.get(pos.getColumnIndex()));
}

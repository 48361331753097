import {type Editor, type NodeProperties, type SlateError, type Text} from '@englex/slate';

import {type SchemaBlockPlugin, type SchemaBlockRules} from '../../../interface';
import {SlateBlock, SlateObject} from '../../../../../interface';
import {isBlockOfType} from '../../../../../utils';
import DialogCellRenderer from '../../../../../plugins/renderers/Table/Dialog/DialogCell';

export default class DialogCell extends DialogCellRenderer implements SchemaBlockPlugin {
  public blockRules = (): SchemaBlockRules => ({
    type: SlateBlock.DIALOG_CELL,
    rules: {
      nodes: [
        {
          match: [{object: SlateObject.BLOCK}]
        }
      ]
    },
    normalizer: {
      predicate: ({node}: SlateError) => isBlockOfType(node, SlateBlock.DIALOG_CELL),
      reasons: {
        child_object_invalid: (change: Editor, {child}: SlateError) => {
          change.wrapNodeByKey((child as Text).key, {
            type: SlateBlock.DEFAULT,
            object: SlateObject.BLOCK
          } as NodeProperties);
          return true;
        }
      }
    }
  });
}

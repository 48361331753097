import classNames from 'classnames';
import React from 'react';
import {findDOMNode} from 'react-dom';
import {
  type Editor as SlateReactEditor,
  type Plugin,
  type RenderBlockProps
} from '@englex/slate-react';
import {
  Block,
  type Document,
  type Editor,
  type Next,
  type Query,
  type SchemaProperties,
  type SlateError
} from '@englex/slate';
import Immutable from 'immutable';

import {type ImageV2} from 'store/interface';

import {
  type ButtonDisablerPlugin,
  ButtonType,
  type PluginDisablerPredicate,
  type SchemaBlockPlugin,
  type SchemaBlockRules
} from '../../interface';
import {
  BlockFloat,
  getToolbarButtonDisablers,
  type ImageBlock,
  SlateBlock,
  SlateObject
} from '../../../../interface';
import {logNormalizeError} from '../../utils';
import {isBlockOfType, isImageBlock} from '../../../../utils';
import type ImageTextWrap from './ImageTextWrap';
import ImageButton from './ImageButton';
import ImageTextWrapLeft from './ImageTextWrapLeft';
import ImageTextWrapCenter from './ImageTextWrapCenter';
import ImageTextWrapRight from './ImageTextWrapRight';
import {ImageResizeButton} from './ImageResizeButton';
import SlateImageResizable from '../../components/SlateImageResizable';
import {selectionHasImages} from './utils';
import {IMAGE_MAX_WIDTH, IMAGE_MIN_RENDER_HEIGHT, IMAGE_MIN_WIDTH} from './static';

interface Size {
  originalWidth: number;
  originalHeight: number;
}

type OriginalSizeMap = Map<number, Size>;

interface Props {
  maxWidth?: number;
  maxHeight?: number;
  defaultFloat?: BlockFloat;
  withImageTextWrapLeft?: boolean;
  withImageTextWrapCenter?: boolean;
  withImageTextWrapRight?: boolean;
}

class Image implements Plugin, SchemaBlockPlugin, ButtonDisablerPlugin {
  private imagesCache = Immutable.Map<number, ImageV2>();
  private maxWidth?: number;
  private maxHeight?: number;
  private defaultFloat: BlockFloat;
  private withImageTextWrapLeft: boolean;
  private withImageTextWrapCenter: boolean;
  private withImageTextWrapRight: boolean;

  private originalSizeMap: OriginalSizeMap = new Map();

  constructor({
    maxWidth,
    maxHeight,
    defaultFloat = BlockFloat.CENTER,
    withImageTextWrapLeft = true,
    withImageTextWrapCenter = true,
    withImageTextWrapRight = true
  }: Props = {}) {
    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;
    this.defaultFloat = defaultFloat;
    this.withImageTextWrapLeft = withImageTextWrapLeft;
    this.withImageTextWrapCenter = withImageTextWrapCenter;
    this.withImageTextWrapRight = withImageTextWrapRight;

    this.withImageTextWrapRight && this.plugins.unshift(new ImageTextWrapRight({toolbar: this}));
    this.withImageTextWrapCenter && this.plugins.unshift(new ImageTextWrapCenter({toolbar: this}));
    this.withImageTextWrapLeft && this.plugins.unshift(new ImageTextWrapLeft({toolbar: this}));
  }

  private getOriginalSize = (imageId: number) => {
    return this.originalSizeMap.get(imageId) as Size;
  };

  public plugins: Array<ImageTextWrap | ImageResizeButton | ImageButton> = [
    new ImageResizeButton({
      toolbar: this,
      maxWidth: this.maxWidth ?? IMAGE_MAX_WIDTH,
      minWidth: IMAGE_MIN_WIDTH,
      minHeight: IMAGE_MIN_RENDER_HEIGHT,
      getOriginalSize: this.getOriginalSize
    }),
    new ImageButton({toolbar: this})
  ];

  public schema: SchemaProperties = {
    rules: [
      {
        match: {object: SlateObject.DOCUMENT},
        last: n => !isBlockOfType(n, SlateBlock.IMAGE),
        normalize: (change: Editor, error: SlateError) => {
          if (error.code === 'last_child_invalid') {
            change.insertNodeByKey(
              error.node.key,
              (error.node as Document).nodes.size,
              Block.create(SlateBlock.DEFAULT)
            );
            logNormalizeError(error, 'Image.schema.rules', true, '0');
            return;
          }
          logNormalizeError(error, 'Image.schema.rules', false, '0');
        }
      }
    ]
  };

  public disableButtons = {
    [ButtonType.BOLD]: selectionHasImages,
    [ButtonType.ITALIC]: selectionHasImages,
    [ButtonType.STRIKE_THROUGH]: selectionHasImages,
    [ButtonType.UNDERLINE]: selectionHasImages,
    [ButtonType.COLOR]: selectionHasImages,
    [ButtonType.HIGHLIGHT]: selectionHasImages,
    [ButtonType.FONT_SIZE]: selectionHasImages,

    [ButtonType.ORDERED_LIST]: selectionHasImages,
    [ButtonType.UNORDERED_LIST]: selectionHasImages,

    [ButtonType.GAP_FILL_DND]: selectionHasImages,
    [ButtonType.GAP_FILL_DROP_DOWN]: selectionHasImages,
    [ButtonType.GAP_FILL_INPUT]: selectionHasImages,

    [ButtonType.DIALOG]: selectionHasImages,
    [ButtonType.CHAR_SELECTOR]: selectionHasImages
  };

  public blockRules = (): SchemaBlockRules => ({
    type: SlateBlock.IMAGE,
    rules: {
      isVoid: true,
      data: {
        id: (v?: number) => typeof v === 'number',
        style: () => true,
        src: (s?: string) => s === undefined,
        width: (w?: number) =>
          w !== undefined && w > 0 && (this.maxWidth ? w <= this.maxWidth : true),
        height: (h?: number) =>
          h !== undefined && h > 0 && (this.maxHeight ? h <= this.maxHeight : true)
      }
    },
    normalizer: {
      predicate: ({node}: SlateError) => isBlockOfType(node, SlateBlock.IMAGE),
      reasons: {
        node_data_invalid: (change: Editor, error: SlateError) => {
          const node = error.node as Block;
          const width = node.data.get('width');
          const height = node.data.get('height');

          if (node.data.get('src')) {
            change.setNodeByKey(node.key, {data: node.data.delete('src')});
            return true;
          }
          if (this.maxWidth && width && width > this.maxWidth) {
            const imageProportions = height / width;
            change.setNodeByKey(node.key, {
              data: {
                ...node.data.toObject(),
                width: this.maxWidth,
                height: this.maxWidth * imageProportions
              }
            });
            return true;
          }

          if (this.maxHeight && height && height > this.maxHeight) {
            const imageProportions = height / width;
            change.setNodeByKey(node.key, {
              data: {
                ...node.data.toObject(),
                height: this.maxHeight,
                width: this.maxHeight / imageProportions
              }
            });
            return true;
          }
          return;
        }
      }
    }
  });

  public getImageFromCache = (id: number) => this.imagesCache.get(id);

  public writeImageInCache = (id: number, image: ImageV2) =>
    (this.imagesCache = this.imagesCache.set(id, image));

  private onImageLoaded =
    (imageId: number) =>
    ({width, height}: ImageV2) => {
      this.originalSizeMap.set(imageId, {
        originalWidth: width,
        originalHeight: height
      });
    };

  public renderBlock = (props: RenderBlockProps, editor: Editor & SlateReactEditor, next: Next) => {
    const {node, attributes, isSelected} = props;
    if (!isImageBlock(node)) {
      return next();
    }

    const block: ImageBlock = node;
    const data = block.data;
    const width = data.get('width');
    const height = data.get('height');
    const float = data.get('float', this.defaultFloat);
    const style = data.get('style');
    const imageId = data.get('id');

    const classes = classNames(
      'slate-image-block',
      style
        ? Object.keys(style)
            .map(p => `style-${p}-${style[p]}`)
            .join(' ')
        : '',
      `float-${float}`
    );

    return (
      <div {...attributes} className={classes} style={style}>
        <SlateImageResizable
          plugins={this.plugins}
          block={node}
          editor={editor}
          getEditorNode={() => this.getEditorNode(editor)}
          isSelected={isSelected}
          maxWidth={this.maxWidth}
          maxHeight={this.maxHeight}
          width={width}
          height={height}
          imageId={imageId}
          writeImageInCache={this.writeImageInCache}
          getImageFromCache={this.getImageFromCache}
          onImageLoaded={this.onImageLoaded(imageId)}
        />
      </div>
    );
  };

  public onQuery = (query: Query, editor: Editor, next: Next) => {
    if (query.type === getToolbarButtonDisablers) {
      const predicates: PluginDisablerPredicate[] = next() || [];
      const buttonType: ButtonType = query.args[0];
      if (buttonType && Object.keys(this.disableButtons).includes(buttonType)) {
        predicates.unshift(this.disableButtons[buttonType]);
      }
      return predicates;
    }
    return next();
  };

  private getEditorNode = (editor: Editor & SlateReactEditor) => findDOMNode(editor) as Element;
}
export default Image;

import {getEventTransfer, type Plugin} from '@englex/slate-react';
import type React from 'react';
import {type Block, type Document, Editor, type Value} from '@englex/slate';
import {List} from 'immutable';
import type Html from '@englex/slate-html-serializer';

import HtmlSerializer, {collapseEmptyBlocks} from '../../../HtmlSerializer';
import createRow from '../utils/createRow';
import TablePosition from '../utils/TablePosition';
import {editHTMLFromMSWord, isPastingFromMSWord} from '../../../utils';

interface DialogueRow {
  name: string;
  text: string;
}

interface RowSwitcher {
  getCurrentRow: (document: Document) => Block;
  nextRow: (document: Document) => Document;
}

export const DialogPaste = (): Plugin => {
  const documentAppendRow = (document: Document, currentRowKey: string): Document => {
    const rowPath = document.getPath(currentRowKey) as List<number>;
    return document.setIn(
      ['nodes', rowPath.get(0), 'nodes'],
      document.nodes.get(rowPath.get(0)).get('nodes').push(createRow(2))
    ) as Document;
  };

  const eventToDialogRows = (e: React.ClipboardEvent): DialogueRow[] | undefined => {
    const {html} = getEventTransfer(e.nativeEvent);
    if (!html) {
      return;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    if (import.meta.env.MODE === 'development') {
      /* eslint-disable no-console */
      console.groupCollapsed('DialogPaste HTML:');
      console.log('HTML:', html);
      console.log('DOM:', doc);
      console.groupEnd();
      /* eslint-enable no-console */
    }

    const table = doc.getElementsByTagName('table').item(0);
    if (table) {
      const rows: DialogueRow[] = [];
      for (const r of table.rows) {
        if (r.cells.length === 2) {
          rows.push({
            name: r?.cells?.item(0)?.textContent?.trim() || '',
            text: r?.cells?.item(1)?.innerHTML || ''
          });
        }
      }

      return rows;
    }

    return undefined;
  };

  const fillDocumentDialogRow = (
    document: Document,
    dialogueRow: DialogueRow,
    rowSwitcher: RowSwitcher,
    serializer: Html
  ) => {
    const currentRow = rowSwitcher.getCurrentRow(document);
    const firstChild = currentRow?.nodes.get(0) as Block;
    const secondChild = currentRow?.nodes.get(1) as Block;
    const rowPath = document.getPath(currentRow.key);
    const currentDocument = rowSwitcher.nextRow(document);

    const editedHTML = isPastingFromMSWord(dialogueRow.text)
      ? editHTMLFromMSWord(dialogueRow.text)
      : dialogueRow.text;
    const htmlValue = serializer.deserialize(editedHTML) as Value;
    const fragmentEditor = new Editor({value: htmlValue});
    const fragment = fragmentEditor.command(collapseEmptyBlocks).value.document;

    return currentDocument.setIn(
      ['nodes', rowPath?.get(0), 'nodes', rowPath?.get(1), 'nodes'],
      List([
        firstChild.setIn(['nodes', 0, 'nodes', 0, 'text'], dialogueRow.name),
        secondChild.set('nodes', fragment.get('nodes'))
      ])
    );
  };

  const getRowSwitcher = (initialRowKey: string) => {
    let currentRowKey = initialRowKey;

    const getCurrentRow = (document: Document) => document.getNode(currentRowKey) as Block;

    const nextRow = (document: Document) => {
      const isLastRow = !document.getNextSibling(currentRowKey);
      const currentDocument = isLastRow ? documentAppendRow(document, currentRowKey) : document;
      const nextRow = currentDocument.getNextSibling(currentRowKey) as Block;
      currentRowKey = nextRow.key;
      return currentDocument;
    };

    return {getCurrentRow, nextRow};
  };

  return {
    onPaste: (e, editor, next) => {
      const {selection} = editor.value;
      if (!selection || selection.isUnset) {
        return next();
      }

      const pos = TablePosition.create(editor.value.document, selection.start.key);
      if (!pos.isInTable() || !pos.cellBlock || !pos.isFirstCell()) {
        return next();
      }

      const tableRows = eventToDialogRows(e);

      if (!tableRows) {
        return next();
      }
      const node = pos.cellBlock;
      const row = editor.value.document.getParent(node.key) as Block;
      const rowSwitcher = getRowSwitcher(row.key);
      const serializer = new HtmlSerializer().getSerializer(editor);

      const newDocument = tableRows.reduce((accumulator: Document, currentRow: DialogueRow) => {
        return fillDocumentDialogRow(accumulator, currentRow, rowSwitcher, serializer);
      }, editor.value.document);

      const newValue = editor.value.set('document', newDocument) as Value;

      const isLastRowEmpty =
        rowSwitcher.getCurrentRow(newValue.document).nodes.get(0).text.trim() === '';

      const resultValue = isLastRowEmpty
        ? (newValue.set(
            'document',
            newValue.document.removeNode(rowSwitcher.getCurrentRow(newValue.document).key)
          ) as Value)
        : newValue;

      const resultEditor = new Editor({value: resultValue});

      const parent = resultEditor.value.document.getParent(row.key);

      if (parent) {
        const fragment = resultEditor.moveAnchorToStartOfNode(node).moveFocusToEndOfNode(parent)
          .value.fragment;

        const tableNode = editor.value.document.getParent(row.key);

        if (tableNode) {
          e.preventDefault();
          e.stopPropagation();
          editor.removeNodeByKey(tableNode.key);
          editor.insertFragment(fragment);
          return;
        }
      }
      return next();
    }
  };
};

import {type Block, type CommandFunc, type Editor, type Path, type Range} from '@englex/slate';

import {SlateBlock} from '../../../../interface';
import {getExampleBlocks} from './queries';

const wrapExample: CommandFunc<Editor> = editor => {
  const {
    value: {document, selection}
  } = editor;
  const range = selection?.toRange() as Range;
  if (!range || range.isUnset) return editor;

  const furthestStart = document.getFurthestChild(range.start.path);
  const furthestEnd = document.getFurthestChild(range.end.path);
  if (furthestStart && furthestEnd && furthestStart === furthestEnd) {
    editor.wrapNodeByKey(furthestStart.key, {object: 'block', type: SlateBlock.EXAMPLE});
  } else {
    editor.wrapBlock({type: SlateBlock.EXAMPLE});
  }

  return editor;
};

const unwrapExample: CommandFunc<Editor> = (editor, exampleEntries: [Block, Path][]) => {
  editor.withoutNormalizing(() => {
    for (const [, path] of exampleEntries.reverse()) {
      editor.unwrapBlockByPath(path, SlateBlock.EXAMPLE);
    }
  });
  return editor;
};

export const toggleExample: CommandFunc = (editor: Editor) => {
  const {
    value: {selection}
  } = editor;
  const range = selection?.toRange() as Range;
  if (!range || range.isUnset) return editor;

  const exampleEntries: [Block, Path][] = editor.query(getExampleBlocks);
  const isActive = !!exampleEntries.length;

  if (isActive) {
    editor.command(unwrapExample, exampleEntries);
  } else {
    editor.command(wrapExample);
  }

  return editor;
};

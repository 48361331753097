import {type Block, type Editor} from '@englex/slate';

import {isBlockOfType} from '../../../../../utils';
import {SlateBlock} from '../../../../../interface';

export function selectionHasDialog(editor: Editor) {
  const {blocks, document} = editor.value;
  return !!blocks.find(block =>
    isBlockOfType(document.getParent(block!.key) as Block, SlateBlock.DIALOG_CELL)
  );
}

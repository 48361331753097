import {type Block, type Editor, type QueryFunc, type RangeType} from '@englex/slate';

import {SlateBlock} from '../../../../interface';

export const getExampleBlocks = (
  editor: Editor,
  at: RangeType | undefined | null = editor.value.selection
): [Block, number[]][] => {
  if (!at || at.isUnset) {
    return [];
  }

  const exampleEntries = editor.value.document.nodes.reduce(
    (result: [Block, number[]][], b: Block, index: number) => {
      b.type === SlateBlock.EXAMPLE && result.push([b, [index]]);
      return result;
    },
    []
  );

  return exampleEntries.filter(([, path]) => editor.value.document.isInRange(path, at));
};

export const isExampleActive: QueryFunc = (editor: Editor): boolean => {
  return !!getExampleBlocks(editor).length;
};

import {Block} from '@englex/slate';
import {Range} from 'immutable';

import {SlateBlock} from '../../../../../interface';
import createRow from '../utils/createRow';

function createDialogTable(isDialogTable: boolean, columns: number, rows: number): Block {
  const rowNodes = Range(0, rows)
    .map(() => createRow(columns))
    .toList();

  return Block.create({
    type: SlateBlock.DIALOG_TABLE,
    nodes: rowNodes
  });
}

export default createDialogTable;

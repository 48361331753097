import ImageTextWrap from './ImageTextWrap';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {BlockFloat} from '../../../../interface';

class ImageTextWrapCenter extends ImageTextWrap {
  public float = BlockFloat.CENTER;
  public type = ButtonType.IMAGE_TEXT_WRAP_CENTER;
  public title = buttonTitle.ImageTextWrapCenter;
  public icon = 'virc-text-wrapping-disabled';
  public shortcut = 'mod+shift+alt+c';
}

export default ImageTextWrapCenter;

import {type Block, type Editor} from '@englex/slate';

import TablePosition from '../utils/TablePosition';

export default function moveSelectionBy(
  change: Editor,
  x: number, //  Move horizontally by x
  y: number // Move vertically by y
): Editor {
  const {value} = change;
  if (!value.selection?.start.key) {
    return change;
  }
  const pos = TablePosition.create(value.document, value.selection.start.key);
  if (!pos.isInCell()) {
    throw new Error('moveSelectionBy can only be applied in a cell');
  }

  const rowIndex = pos.getRowIndex();
  const colIndex = pos.getColumnIndex();
  const width = pos.getWidth();
  const height = pos.getHeight();

  const [absX, absY] = normPos(x + colIndex, y + rowIndex, width, height);
  const isGoingUp = y < 0;

  if (absX === -1) {
    // Out of table
    return change;
  }

  const {table} = pos;
  const row = table.nodes.get(absY);
  const cell = (row as Block).nodes.get(absX);

  if (isGoingUp) {
    change.moveToEndOfNode(cell);
  } else {
    change.moveToStartOfNode(cell);
  }

  return change;
}

function normPos(x: number, y: number, width: number, height: number): number[] {
  if (x < 0) {
    x = width - 1;
    y -= 1;
  }

  if (y < 0) {
    return [-1, -1];
  }

  if (x >= width) {
    x = 0;
    y += 1;
  }

  if (y >= height) {
    return [-1, -1];
  }

  return [x, y];
}

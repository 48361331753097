import React, {useCallback, useState} from 'react';
import Tooltip from 'rc-tooltip';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';
import {useIntl} from 'react-intl';

import {renderShortcut} from 'helpers/shortcut';

import {type ImageReduceSizeFactor, imageReduceSizeFactor} from './constants';
import messages from '../i18n';

import './ImageResizeTooltip.scss';

const trigger = ['click'];

const preventDefault = (e: React.MouseEvent) => e.preventDefault();

interface Props extends Omit<TooltipProps, 'overlay'> {
  onResize: (sizeFactor: number) => void;
}

export const ImageResizeTooltip: React.FC<Props> = ({children, onResize, ...rest}) => {
  const {formatMessage} = useIntl();

  const [visible, setVisible] = useState(false);

  const renderOverlay = useCallback(() => {
    const getFormatMessage = (sizeFactor: ImageReduceSizeFactor) => {
      switch (sizeFactor) {
        case 'ORIGINAL': {
          return formatMessage(messages.originalSize);
        }
        case 'HALF': {
          return formatMessage(messages.half);
        }
        case 'THIRD': {
          return formatMessage(messages.third);
        }
        case 'QUARTER': {
          return formatMessage(messages.quarter);
        }
      }
    };

    const variations = Object.entries(imageReduceSizeFactor).map(
      ([key, value]: [ImageReduceSizeFactor, number]) => {
        const message = getFormatMessage(key);
        return {
          text: message,
          value: value,
          title: `${message} (${renderShortcut('ctrl+shift+' + value)})`
        };
      }
    );

    return (
      <ul className="image-resize-tooltip-overlay">
        {variations.map(({text, value, title}) => {
          const handleClick = () => {
            onResize(value);
            setVisible(false);
          };

          return (
            <li
              key={text}
              title={title}
              className="image-resize-tooltip-overlay__variation"
              onMouseDown={preventDefault}
              onClick={handleClick}
            >
              {text}
            </li>
          );
        })}
      </ul>
    );
  }, [formatMessage, onResize]);

  return (
    <Tooltip
      visible={visible}
      trigger={trigger}
      overlay={renderOverlay}
      placement="bottom"
      showArrow={false}
      onVisibleChange={setVisible}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

import {type Block, type Editor, type Next} from '@englex/slate';
import {type Editor as ReactEditor} from '@englex/slate-react';
import type React from 'react';

import isShortcut from 'helpers/shortcut';

import onUpDown from './onUpDown';
import startOrEndBlockInTable from '../utils/startOrEndBlockInTable';
import {startAndEndBlockInOneCell} from '../utils/startAndEndBlockInOneCell';
import onTab from './onTab';

export default function onKeyDown(
  event: React.KeyboardEvent,
  change: ReactEditor & Editor,
  next: Next
) {
  const {value} = change;
  const {startBlock, document, selection} = value;

  if (!startOrEndBlockInTable(value)) {
    return next();
  }

  if (isShortcut(event, 'tab') || isShortcut(event, 'shift+tab')) {
    onTab(event, change);
    return;
  }

  if (isShortcut(event, 'up') || isShortcut(event, 'down')) {
    onUpDown(event, change);
    return;
  }

  if ((!startAndEndBlockInOneCell(change.value) && !isShortcut(event, 'mod+c')) || !startBlock) {
    event.preventDefault();
    return;
  }

  const startBlockParent = document.getParent(startBlock.key) as Block;
  if (
    isShortcut(event, 'delete') &&
    !selection?.isExpanded &&
    selection?.start.isAtEndOfNode(startBlockParent)
  ) {
    event.preventDefault();
    return;
  }

  if (
    isShortcut(event, 'backspace') &&
    !selection?.isExpanded &&
    selection?.start.isAtStartOfNode(startBlockParent)
  ) {
    event.preventDefault();
    return;
  }

  return next();
}

import ToolbarButton from '../../ToolbarButton';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {removeRow} from './Changes/removeRow';

export default class RemoveRow extends ToolbarButton {
  public icon = 'trash';
  public type = ButtonType.REMOVE_ROW;
  public title = buttonTitle[ButtonType.REMOVE_ROW];
  protected neverDisable = true;

  public toggleChange = removeRow;
}

import React from 'react';
import {type ResizeCallback, Resizable} from 're-resizable';
import classNames from 'classnames';
import {type Block, type Editor} from '@englex/slate';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import {type ImageV2} from 'store/interface';

import ControlledTooltip from './ControlledTooltip';
import SlateToolbar from '../../Toolbar/SlateToolbar';
import type ToolbarButton from '../ToolbarButton';
import {
  IMAGE_MAX_WIDTH,
  IMAGE_MIN_RENDER_HEIGHT,
  IMAGE_MIN_WIDTH,
  IMAGE_RESIZE_GRID
} from '../button/Image/static';
import {type ImageData, type DataMap} from '../../../interface';
import LoadableImage from '../../../../LoadableImage/LoadableImage';
import './SlateImage.scss';

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
  plugins: ToolbarButton[];
  block: Block;
  editor: Editor;
  getEditorNode: () => Element | null;
  isSelected: boolean;
  width: number;
  height: number;
  maxWidth?: number;
  maxHeight?: number;
  style?: React.CSSProperties;
  imageId: number;
  writeImageInCache: (id: number, img: ImageV2) => void;
  getImageFromCache: (id: number) => ImageV2 | undefined;
  onImageLoaded?: (img: ImageV2) => void;
}

interface State {
  visible: boolean;
  selected: boolean;
  maxWidth: number;
  maxHeight?: number;
  error: boolean;
  aspectRatio: number;
  resizingEnabled?: boolean;
}

class SlateImageResizable extends React.Component<Props, State> {
  protected static MAX_WIDTH = IMAGE_MAX_WIDTH;
  private static MIN_WIDTH = IMAGE_MIN_WIDTH;
  private static MIN_HEIGHT = IMAGE_MIN_RENDER_HEIGHT;
  private resizable: Resizable | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      selected: props.isSelected,
      maxWidth: props.maxWidth ? props.maxWidth : SlateImageResizable.MAX_WIDTH,
      maxHeight: props.maxHeight ? props.maxHeight : undefined,
      error: false,
      aspectRatio: this.props.width / this.props.height
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.maxWidth !== state.maxWidth || props.maxHeight !== state.maxHeight) {
      return {maxWidth: props.maxWidth, maxHeight: props.maxHeight};
    }

    return null;
  }

  public render() {
    const {width, height, editor} = this.props;
    const readOnly = editor.readOnly;

    if (readOnly) {
      return this.renderImg(`${width}px`, `${height}px`);
    }
    const resizeEnable = !readOnly && !!this.state.resizingEnabled;
    const enable = {
      top: resizeEnable,
      right: resizeEnable,
      bottom: resizeEnable,
      left: resizeEnable,
      topRight: resizeEnable,
      bottomRight: resizeEnable,
      bottomLeft: resizeEnable,
      topLeft: resizeEnable
    };
    return (
      <Resizable
        ref={(el: Resizable) => (this.resizable = el)}
        size={{width, height}}
        maxWidth={this.state.maxWidth}
        maxHeight={this.state.maxHeight}
        minWidth={SlateImageResizable.MIN_WIDTH}
        minHeight={SlateImageResizable.MIN_HEIGHT}
        lockAspectRatio={true}
        onResizeStop={this.onResizeStop}
        enable={enable}
        grid={[IMAGE_RESIZE_GRID, IMAGE_RESIZE_GRID / this.state.aspectRatio]}
        className={classNames([
          'slate-image-resizable',
          {
            selected: this.props.isSelected,
            error: this.state.error
          }
        ])}
      >
        {this.renderImgTooltip()}
      </Resizable>
    );
  }

  private onImageLoaded = (img: ImageV2) => {
    const {width, height} = img;
    // in this handler we disallow making small images larger then the source img file
    const adjustedWidth = width - (width % IMAGE_RESIZE_GRID);
    this.props.writeImageInCache(img.id, img);
    this.setState({
      maxWidth: adjustedWidth <= this.state.maxWidth ? adjustedWidth : this.state.maxWidth,
      maxHeight: height,
      resizingEnabled: true
    });

    this.props.onImageLoaded?.(img);
  };

  private saveSize = (change: Editor, width: number, height: number) => {
    const {block} = this.props;
    const {data} = block;
    change.setNodeByKey(block.key, {
      data: data.withMutations((d: DataMap<ImageData>) =>
        d.set('width', width).set('height', height)
      )
    });
  };

  private onResizeStop: ResizeCallback = (e, direction, ref, delta) => {
    const {editor, width, height} = this.props;
    const newWidth = width + delta.width;
    const newHeight = height + delta.height;
    editor.command(this.saveSize, newWidth, newHeight);
  };

  private overlay = () => {
    return (
      <SlateToolbar
        className="slate-editor-toolbar"
        show={true}
        plugins={this.props.plugins}
        editor={this.props.editor}
      />
    );
  };

  private renderImgTooltip = () => {
    return (
      <ControlledTooltip
        prefixCls="slate-nested-toolbar"
        overlay={this.overlay()}
        getTooltipContainer={() => this.resizable!.resizable!}
        overlayClassName="image-float-toolbar"
        align={{
          points: ['tc', 'tc'],
          offset: [0, 5]
        }}
      >
        {this.renderImg('100%', '100%')}
      </ControlledTooltip>
    );
  };

  private renderImg = (width: string, height: string) => {
    return (
      <div style={{width, height}}>
        <LoadableImage
          imageId={this.props.imageId}
          width={width}
          height={height}
          onImageLoaded={this.onImageLoaded}
          img={this.props.getImageFromCache(this.props.imageId)}
        />
      </div>
    );
  };
}

export default injectIntl(SlateImageResizable);

import {type Block, type Value} from '@englex/slate';

import {isBlockOfType} from '../../../../../utils';
import {SlateBlock} from '../../../../../interface';

export default function startOrEndBlockInTable({startBlock, endBlock, document}: Value) {
  if (!startBlock || !endBlock) {
    return false;
  }
  const startBlockInTable = isBlockOfType(
    document.getParent(startBlock.key) as Block,
    SlateBlock.DIALOG_CELL
  );
  const endBlockInTable = isBlockOfType(
    document.getParent(endBlock.key) as Block,
    SlateBlock.DIALOG_CELL
  );
  return startBlockInTable || endBlockInTable;
}

import {type Editor} from '@englex/slate';

import {isImageBlock} from '../../../../utils';
import {BlockFloat} from '../../../../interface';

const toggleImageFloat = (change: Editor, float: BlockFloat) => {
  const block = change.value.blocks.first();
  const {data} = block;
  if (isImageBlock(block)) {
    change.setBlocks({
      data: float === BlockFloat.CENTER ? data.delete('float') : data.set('float', float)
    });
  }
};

export function selectionHasImages({value: {blocks}}: Editor) {
  return !!blocks.find(block => !!isImageBlock(block!));
}

export default toggleImageFloat;
